// implementation from https://github.com/ant-design/ant-design/issues/22928

import React, { useCallback, useEffect, useState } from 'react';
import Table from 'antd/lib/table';
import { useVT } from 'virtualizedtableforantd4';


const getWindowHeight = () =>
  window.innerHeight -
  (20 * 2 + 44 + 55);

function useResizeAwareWindowHeight() {
  const [windowHeight, setWindowHeight] = useState(800);
  const triggerRefresh = useCallback(() => {
    setWindowHeight(getWindowHeight());
  }, [setWindowHeight]);
  useEffect(() => {
    window.addEventListener('resize', triggerRefresh);
    return () => window.removeEventListener('resize', triggerRefresh);
  }, []);
  // set height on first time
  useEffect(() => triggerRefresh(), []);
  return windowHeight;
}



const SmartTable = <T extends object = any>({
  debug = false,
  dataSource,
  heightOffset = 0,
  onFetch,
  ...props
}) => {

  const windowHeight = useResizeAwareWindowHeight();
  const [vt] = useVT(
    () => ({
      onScroll: ({ isEnd }) => {
        if (isEnd) {
          console.log('loadDataByChunk');
          onFetch();
        }
      },
      scroll: { y: windowHeight-heightOffset },
      debug,
    }),
    [windowHeight,dataSource]
  );

  return (
    <Table<T> dataSource={dataSource} scroll={{
      y: windowHeight-heightOffset,
      x: true,
    }} components={vt} pagination={false} {...props} />
  );
};

export default SmartTable;