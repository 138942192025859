import React, { useState, useEffect } from 'react';
import { Alert, Table, Descriptions, Row, Col, Radio, Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { CheckCircleTwoTone, InfoCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';


import ReactTags from 'react-tag-autocomplete';
import Highlight from 'react-highlighter';

import 'antd/dist/antd.css';

import './App.css';
import { Contact, Publication, ResearchedGene, Tag, QueryType, Suggestion, Query } from './Types';
import { getSuggestions, getContacts } from './utils/Api';
import ParradigmLogo from './parradigm.svg';
import { useDebouncedCallback } from 'use-debounce';
import SmartTable from './utils/SmartTable';
import extractEmail from 'extract-email-address';

function App() {
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [contacts, setContacts] = useState<Contact[]>([]);

  const [tags, setTags] = useState<Tag[]>([]);
  const [query, setQuery] = useState<null | Query>(null);
  const [orthologMatching, setOrthologMatching] = useState<boolean>(false);


  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  const columns: ColumnsType<Contact> = [
    {
      
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: '12%',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: '12%',
      
    },
    {
      title: 'Affiliation',
      dataIndex: 'affiliation',
      key: 'affiliation',
      width: '35%',
      // render: (e:string) => e.split(',').reverse().splice(1).reverse().join(',')
    },
    // {
    //   title: 'Country',
    //   dataIndex: 'country',
    //   key: 'country',
    //   width: '10%',
    // },
    {
      title: 'Model organisms',
      dataIndex: 'model_organisms',
      key: 'model_organisms',
      render: (e) => e.join(', ')
    }
  ];

  const expandedRowRender = (e:Contact) => {
    const pub_cols: ColumnsType<Publication> = [
      {
        title: 'PMID',
        dataIndex: 'pmid',
        key: 'pmid',
        width: '25%',
        render: (pmid : string) => <a href={`https://www.ncbi.nlm.nih.gov/pubmed/?term=${pmid}`} target='_blank'>{pmid}</a>
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title'
      }
    ];

    const gene_cols: ColumnsType<ResearchedGene> = [
      {
        title: 'Symbol',
        dataIndex: 'gene',
        key: 'symbol',
        width: '25%',
        render: (gene : {symbol: string; id: number}) => <a href={`https://www.ncbi.nlm.nih.gov/gene/${gene.id}`} target='_blank'>{gene.symbol.toUpperCase()}</a>
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Taxon',
        dataIndex: 'taxon',
        key: 'taxon',
      }
    ];

    return <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} className="gutter-row" >
      <Descriptions title="Model Organism Researcher Profile" bordered column={1}>
        <Descriptions.Item label="First Name">{e.first_name}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{e.last_name}</Descriptions.Item>
        {e.affiliation && extractEmail(e.affiliation).length > 0 && <Descriptions.Item label="Email">{extractEmail(e.affiliation).map(e=>e.email).join('\n')}</Descriptions.Item>}
        {e.affiliation && <Descriptions.Item label="Affiliation">{e.affiliation.split(',').map(s => <>{s}<br/></>)}</Descriptions.Item>}
        {/* <Descriptions.Item label="Country">{e.country}</Descriptions.Item> */}
      </Descriptions>
      <br/>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} className="gutter-row" >
      <div className="ant-descriptions-title" style={{paddingBottom: '20px'}}>Publications</div>

      <Table<Publication>
        columns={pub_cols} 
        dataSource={e.publications}
        pagination={false}
      />

      {e.researched_genes.length > 0 &&
      <>
        <br/>
        <div className="ant-descriptions-title" style={{paddingBottom: '20px'}}>Genes Studied by Researcher</div>
        <Table<ResearchedGene>
          columns={gene_cols}
          dataSource={e.researched_genes}
          pagination={false} 
        />
      </>
      }
      </Col>
    </Row>;
  };


  const typeLabel = (ty: QueryType) => {switch (ty) {
    case 'ortholog': return 'gene'
    case 'goterm': return 'GO term'

    default: return ty
  }}

  const comparisonColumns: ColumnsType = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: '12%',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: '12%',
    },
    ...(query?.species != null ? query.species : []).map((species, i) => ({
      title: () => <>
        <span className="query-type">{typeLabel('taxon')}</span>
        <span className="query-string">{species}</span>
        {/* {taxon && <span className="query-type"> in {taxon}</span>} */}
      </>,
      dataIndex: `species-${i}`,
      key: `species-${i}`,
    })),
    ...(query?.countries != null ? query.countries : []).map((country, i) => ({
      title: () => <>
        <span className="query-type">{typeLabel('country')}</span>
        <span className="query-string">{country}</span>
        {/* {taxon && <span className="query-type"> in {taxon}</span>} */}
      </>,
      dataIndex: `country-${i}`,
      key: `country-${i}`,
    })),
    ...(query?.genes != null ? query.genes : []).map((genes, i) => ({
      title: () => <>
        <span className="query-type">{typeLabel('gene')}</span>
        <span className="query-string">{genes}</span>
        {/* {taxon && <span className="query-type"> in {taxon}</span>} */}
      </>,
      dataIndex: `gene-${i}`,
      key: `gene-${i}`,
    })),

  ];

  const mkCheckOrCross = (x, matched_on) => {
    let m = matched_on.find(e => e === x);
    if(m){
      // if(m.ortholog_gene) return <>
      //     <InfoCircleTwoTone />
      //     <span className="query-type"> matched on</span>
      //     <span className="query-string">{m.ortholog_gene.symbol}</span>
      //     <span className="query-type"> in {m.ortholog_gene.taxon.common_name}</span>
      //   </>

      // <><div>I am present</div></>
      return <CheckCircleTwoTone twoToneColor="#52c41a"/>
      
    }



    return <CloseCircleTwoTone twoToneColor="#f5222d"/>
   
  }

  const contactsComparison = contacts.map(c => ({
    ...c,
    ...Object.fromEntries((query?.countries != null ? query.countries : []).map((g,i) => [`country-${i}`, mkCheckOrCross(g, c.matched_on)])),
    ...Object.fromEntries((query?.genes != null ? query.genes : []).map((g,i) => [`gene-${i}`, mkCheckOrCross(g, c.matched_on)])),
    ...Object.fromEntries((query?.species != null ? query.species : []).map( (g,i) => [`species-${i}`, mkCheckOrCross(g, c.matched_on)]))
  }))

  const displayOptions = [
    { label: 'Full view', value: 'full' },
    { label: 'Comparison view', value: 'comparison' }
  ];

  const [displayOption, setDisplayOption] = useState<string>('full');

  const showDoubleView: boolean = true
    // // We show the full vs comparison view either when the query has more than one parameters
    // tags.length > 1 ||
    // (tags.length === 1 && (
    //   // or the query is a GO term, i which case we want to see which genes we have matched on
    //   tags[0].type === 'goterm' || 
    //   // or we have ortholog matching enabled an the query is a gene/ortholog 
    //   // (this check is in place because we want to persist the value of ortholog matching even when we remove a gene query and the ortholog matching toggle disappears)
    //   (orthologMatching && (tags[0].type === 'gene' || tags[0].type === 'ortholog'))
    // ))
    
  const debounced = useDebouncedCallback((query) => {
    getSuggestions(query).then(
      res => setSuggestions(res)
    )
  }, 200)

  const formatSuggestion = (s: Suggestion) => {
    switch (s.type) {
      case "taxon": return { name: `${s.data.scientific_name} (${s.data.common_name})`, tag_name: s.data.scientific_name, data:s.data, type: s.type }
      case "gene":  return { name: `${s.data.name} (${s.data.symbol.toUpperCase()})`, tag_name: s.data.symbol.toUpperCase(), data:s.data, type: s.type }
      default:      return { name: s.data.name, tag_name: s.data.name, data:s.data, type: s.type }
    }
  }

  const [abortSearch, setAbortSearch] = useState<AbortController | null>(null);

  // initially populate a random set of records
  useEffect(() => {
    setTableLoading(true);
    const q = {
      countries: null,
      genes: null,
      species: null,
      go_terms: null,
      page: 0
    }
    setQuery({...q, page: 1});
    getContacts(q).then(res => {
      setContacts(res.map((e,i) => ({...e, key:i})))
      setTableLoading(false);
    }).catch(() => {
      setContacts([]);
      setTableLoading(false);
    })
  }, [])


  useEffect(() => {
    setTableLoading(false);
    setQuery(null);
    setContacts([]);
    abortSearch && abortSearch.abort()
    if(tags.length === 0){
      setTableLoading(true);
      const q = {
        countries: null,
        genes: null,
        species: null,
        go_terms: null,
        page: 0
      }
      setQuery({...q, page: 1});
      getContacts(q).then(res => {
        setContacts(res.map((e,i) => ({...e, key:i})))
        setTableLoading(false);
      }).catch(() => {
        setContacts([]);
        setTableLoading(false);
      })
     
    };
    setTableLoading(true);
    let aController = new AbortController();
    setAbortSearch(aController);
    const countries:string[] = [];
    const genes:string[] = [];
    const species:string[] = [];
    tags.forEach(e => {
      switch (e.type) {
        case "country":
          countries.push(e.tag_name.toLowerCase());
          break;
        case "gene":
          genes.push(e.data.symbol.toLowerCase());
          break;
        case "taxon":
          species.push(e.data.common_name.toLowerCase());
      }
    });

    const q = {
      countries: countries.length > 0 ? countries : null,
      genes: genes.length > 0 ? genes : null,
      species: species.length > 0 ? species : null,
      go_terms: null,
      page: 0
    }
    setQuery({...q, page: 1});
    getContacts(q, aController.signal).then(res => {
      setContacts(res.map((e,i) => ({...e, key:i})))
      setTableLoading(false);
    }).catch(() => {
      setContacts([]);
      setTableLoading(false);
    })
  }, [tags],)

  return <>
    <div className="logo"><img src={ParradigmLogo} alt="PARRADIGM"/></div>
    <br/>

    <ReactTags
      autoresize
      placeholderText={tags.length === 0 ? "Search by gene symbol or name, researcher affiliation, country, gene associations or GO terms..." : "Enter additional search terms (gene symbol/name or researcher affiliation/country/gene associations/GO terms)..."}
      tags={tags}
      maxSuggestionsLength={50}
      suggestions={suggestions.map((s,i)=> ({id: i, ...formatSuggestion(s)}))}
      onInput={(input) => {debounced.callback(input)}}
      suggestionsTransform={
        (query: string, suggestions: any[]) => {
          // this appaears to be pointless but it isn't!!
          // DO NOT DELETE THIS FUNCTION
          // it forces the table view update after we query the suggestions api
          return suggestions;
        }
      }
      suggestionComponent={
        ({item, query}) => 
        <div id={item.id} className={item.name === query ? 'match' : 'no-match'}>
          <span className="query-type">{item.type}</span>
          <span className="query-string"><Highlight search={query}>{item.name}</Highlight></span>
          {(item.ortholog_to || item.data.taxon) && <span> </span>}
          {item.ortholog_to && <span className="query-type" style={{fontStyle: 'normal'}}> → {item.ortholog_to}</span>}
          {item.data.taxon && <span className="query-type">in {item.data.taxon}</span>}
        </div>
      }
      tagComponent={
        ({ tag, removeButtonText, onDelete }) => 
        <button type='button' className="react-tags__selected-tag" title={removeButtonText} onClick={onDelete}>
          <span className="query-type">{tag.type}</span>
          <span className="query-string">{tag.tag_name}</span>
          {tag.data.taxon && <span className="query-type"> in {tag.data.taxon}</span>}
        </button>
      }
      onDelete={(i:number) => {const newTags = [...tags]; newTags.splice(i, 1); setTags(newTags)}}
      onAddition={(e:Tag) => setTags([...tags, e])}
    />
    {/* {tags.some(q => q.type === 'gene' || q.type === 'ortholog' || q.type === 'goterm') && <div style={{paddingTop: '1em'}}>Ortholog matching: <Switch checkedChildren="on" unCheckedChildren="off" checked={orthologMatching} onChange={checked => setOrthologMatching(checked)} /></div>} */}
    {showDoubleView && 
    <div className="display-options">
      <Radio.Group options={displayOptions} onChange={e => setDisplayOption(e.target.value)} value={displayOption} optionType="button"/>
    </div>}
    <br/>
    <SmartTable<Contact> 
      heightOffset={200}
      loading={tableLoading}
      columns={showDoubleView && displayOption === "comparison" ? comparisonColumns : columns} 
      // columns={columns}
      // dataSource={contacts}
      dataSource={showDoubleView && displayOption === "comparison" ? contactsComparison : contacts}
      expandable={{ expandedRowRender }}

      onFetch={() => {
        if(query){
          const pagesFetched = query.page;
          if(pagesFetched === null) return;
          getContacts(query).then(res => {
            setContacts([...contacts, ...res.map((e,i) => ({...e, key:100*pagesFetched+i}))])
            setQuery({...query, page: res.length > 0 ? pagesFetched + 1 : null});
          })
        }
      }}
    />
  </>;

}

export default App;
