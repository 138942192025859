import * as localforage from 'localforage';
import { Contact, Suggestion, Query } from '../Types';
let localForage: LocalForage = localforage;

function fetchArray<T>(url:string, method: 'GET' | 'POST', body?:any, abortSignal?:any):Promise<T[]> {

  return fetch(
    url,
    {
      method: method,
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      ...(body ? {body : JSON.stringify(body)} : {}),
      ...(abortSignal ? {signal: abortSignal} : {})
    }
  )
    .then((response) => {
      return new Promise<{json:T[]; status:number}>((resolve) => response.json()

        .then((json) => resolve({
        
          status: response.status,
          json,
        })));
    })
    .then(({ status, json }) => {
      switch (status) {
        case 200:
          console.log(json)
        return json;
        default:
        return [];
        
      }
    })
}


function fetchArrayCached<T>(cache_key: string, url:string, method: 'GET' | 'POST', body?:any):Promise<T[]> {
  return localForage.getItem<T[]>(cache_key).then(res => {
    if (res)
      return {json : res, status:200}
    else
      return fetch(
        url,
        {
          method: method,
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            ...(body ? {body : JSON.stringify(body)} : {})
          }
        }
      ).then((response) => {
        return new Promise<{json:T[]; status:number}>((resolve) => response.json()
          .then((json) => resolve({
            status: response.status,
            json,
          })));
      })
    })
  .then(({ status, json }) => {
    switch (status) {
      case 200:
        localForage.setItem(cache_key, json);
        return json;
      default:
        return [];
    }
  })
}

export function getSuggestions(str: string):Promise<Suggestion[]> {
  return fetchArray(`${process.env.REACT_APP_API_URL}/rpc/get_suggestions`, 'POST', {text: str})
}


export function getContacts(q: Query, signal?:any):Promise<Contact[]> {
  return fetchArray(`${process.env.REACT_APP_API_URL}/rpc/get_contacts`, 'POST', q, signal)
}